import React from 'react'
import { graphql, Link } from 'gatsby'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import AnimatedArrow from '../components/animated-arrow'

// Styles
import '../styles/pages/chef-table.scss'
import TitlePattern from '../images/title-pattern.inline.svg'

const ChefTablePage = ({ data: graphqlData, location }) => {
  const {
    pageTitle,
    pageDescription,
    pageImage,
    sectionTitle,
    sectionDescription,
    featuredTitle,
    featuredTopTitle,
    featuredImage,
    featuredDescription,
  } = graphqlData.datoCmsChefTable

  return (
    <Layout className="chef-table-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Chef's Table" />

      <section className="container">
        <h1 dangerouslySetInnerHTML={{ __html: pageTitle }} />

        <div className="main-section section">
          <div dangerouslySetInnerHTML={{ __html: pageDescription }} />
          <TitlePattern />
        </div>

        <figure className="page-image">
          <img src={pageImage.sizes.src} srcSet={pageImage.sizes.srcSet} alt="Chef's Table" />
        </figure>

        <div className="second-section section">
          <h3>{sectionTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: sectionDescription }} />
        </div>

        <figure className="featured-image">
          <img src={featuredImage.sizes.src} srcSet={featuredImage.sizes.srcSet} alt="Chef's Table" />
        </figure>

        <div className="featured">
          <h3>{featuredTopTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: featuredDescription }} />
          <a href="/reservation">
            <span>{featuredTitle}</span>
            <AnimatedArrow className="h-3 text-tuya-rho" />
          </a>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsChefTable {
      pageTitle
      pageDescription
      pageImage {
        sizes {
          src
          srcSet
        }
      }
      sectionTitle
      sectionDescription
      featuredTitle
      featuredTopTitle
      featuredDescription
      featuredImage {
        sizes {
          src
          srcSet
        }
      }
    }
  }
`

export default ChefTablePage
